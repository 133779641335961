import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../Navbar";
import HomeHeaderContent from "./HomeHeaderContent";

const HomeHeader = ({ setActive, setActiveLink, activeLink }) => {
  const [screen, setScreen] = useState(1);
  const [backgroundImages, setBackgroundImages] = useState([]);
  const location = useLocation();

  const toggleScreen = () => {
    setScreen((prev) => (prev === 2 ? 1 : prev + 1));
  };

  const fetchBackgroundImages = async () => {
    try {
      const response = await fetch("https://cms.nti.edu.ng/api/sliders");
      const data = await response.json();
      setBackgroundImages(data);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    fetchBackgroundImages();
  }, []);

  useEffect(() => {
    if (location.pathname === "/") {
      const interval = setInterval(() => {
        toggleScreen();
      }, 10000);
      return () => clearInterval(interval);
    }
    setScreen(1);
  }, [location.pathname]);

  const getCurrentBackgroundImage = () => {
    if (!backgroundImages.length) return '';
    const currentImage = backgroundImages[screen - 1];
    return screen === 1 ? currentImage?.image1 : currentImage?.image2;
  };

  return (
    <header 
      className={``}
      style={{
        backgroundImage: `url(https://cms.nti.edu.ng/images/${getCurrentBackgroundImage()})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        transition: 'background-image 0.5s ease-in-out'
      }}
    >
      <div className="header_wrapper">
        <Navbar
          setActive={setActive}
          setActiveLink={setActiveLink}
          activeLink={activeLink}
        />
        <HomeHeaderContent setScreen={setScreen} screen={screen} />
      </div>
    </header>
  );
};

export default HomeHeader;